import mixpanel from 'mixpanel-browser';
import CookieConsent from 'react-cookie-consent';
import './CookieConsentBar.css';

export default function CookieConsentBar() {
  return (
    <CookieConsent
      style={{ background: '#0635C9' }}
      buttonStyle={{
        textDecoration: 'none',
        padding: '11px 44px 10px 40px',
        background: '#FFFFFF',
        borderRadius: '2px',
        fontWeight: 600,
        fontSize: '1em',
        color: '#0635C9',
        border: '2px solid #0635C9'
      }}
      buttonClasses={'button-text'}
      onAccept={() => mixpanel.opt_in_tracking()}
    >
      <p className={'body-text consent-block'}>This website uses cookies to enhance the user experience.</p>
    </CookieConsent>
  );
}

import React, { useState } from 'react';
import { useMutation } from 'react-query';
import './ContactMe.css';
import cfg from '../../config';


const handleOnChange = (setter) => (event) => {
  event.persist();
  setter(event.target.value);
};

const sendContactForm = async ({name, email, letter}) => {
  try {
    await fetch(cfg.api.post_contact_me, {
      method: 'POST',
      body: JSON.stringify({ name, from: email, content: letter }),
    });
  } catch (e) {
    /**NOP**/
  }
};


export default function ContactMe({}) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [letter, setLetter] = useState('');
  const { mutate, isLoading } = useMutation(sendContactForm);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!name || !email || !letter) {
      return;
    }

    await mutate({name, email, letter});

    setName('');
    setEmail('');
    setLetter('');
  };

  return (
    <form className={'contact-me ' + (isLoading ? 'disabled' : '')} onSubmit={handleSubmit}>
      <div className={'row'}>
        <div className={'col'}>
          <div className={'field'}>
            <label className={'title transparent-2'} htmlFor={'name'}>NAME</label>
            <input className={'body-text'} name={'name'} placeholder={'Your Name'} type="text" value={name}
                   onChange={handleOnChange(setName)}/>
          </div>
        </div>
        <div className={'col'}>
          <div className={'field'}>
            <label className={'title transparent-2'} htmlFor={'email'}>EMAIL</label>
            <input className={'body-text'} placeholder={'Email'} name={'email'} type="email" value={email}
                   onChange={handleOnChange(setEmail)}/>
          </div>
        </div>
      </div>
      <div className={'field'}>
        <label className={'title transparent-2'} htmlFor={'letter'}>LETTER</label>
        <textarea className={'body-text'} name={'letter'} placeholder={'Hey Vlad, what do you think about ...?'}
                  value={letter} onChange={handleOnChange(setLetter)}/>
      </div>
      <button type="submit" className={'button-text submit'}>send a letter</button>
    </form>
  );
}

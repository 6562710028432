const config = {
  mailTo: 'mailto:vladyslav.sheruda@gmail.com?subject=Hi!&body=Hi Vlad! I\'m {username} and I\'d like to talk to you about {text}',
  linkedin: 'vladyslav-sheruda',
  github: 'schermannj',
  twitter: 'schrmnn',
  mixpanel_host: 'https://api-eu.mixpanel.com',
  mixpanel_token: process.env.REACT_APP_MIXPANEL_TOKEN,
  api: {
    post_contact_me: `https://${process.env.REACT_APP_AWS_GATEWAY_ID}.execute-api.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/api/contact`,
  },
};

export default config;

import { QueryClientProvider, QueryClient } from 'react-query'
import mixpanel from 'mixpanel-browser';
import './App.css';
import Summary from './Containers/Summary/Summary';
import Services from './Containers/Services/Services';
import Skills from './Containers/Skills/Skills';
import Experience from './Containers/Experience/Experience';
import Hero from './Containers/Hero/Hero';
import Footer from './Containers/Footer/Footer';
import CookieConsentBar from './Components/CookieConsentBar/CookieConsentBar';
import config from './config';
import c from './constants';

const queryClient = new QueryClient();

function App() {
  mixpanel.track(c.mixpanel.open_home_action);

  return (
    <QueryClientProvider client={queryClient}>
      <div className={'content-container'}>
        <Hero mailTo={config.mailTo} linkedin={config.linkedin} github={config.github} twitter={config.twitter}/>
        <Summary/>
        <Services/>
        <Skills/>
        <Experience/>
      </div>
      <Footer/>
      <CookieConsentBar/>
    </QueryClientProvider>
  );
}

export default App;

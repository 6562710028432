import './Footer.css';

import ContactMe from '../../Components/ContactMe/ContactMe';

export default function Footer() {
  return (
    <div id={'footer'} className={'footer-container'}>
      <div className={'content'}>
        <div className={'left'}>
          <h2 className={'title'}>Just say hi<span className={'transparent-2'}>!</span></h2>
          <p className={'footer-text body-text transparent-2'}>
            I'm always open to discuss your project and talk about new things.
          </p>
        </div>
        <div className={'right'}>
          <ContactMe/>
        </div>
      </div>
      <div className={'divider'}/>
      <span className={'body-text'}>© 2021 Vladyslav Sheruda. – All Rights Reserved.</span>
    </div>
  );
}
